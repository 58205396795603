import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	// Nav
	$( 'header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#nav-collapse' ).fadeToggle();
	} );

	$( 'nav>ul>li>a:not(.external)' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 2000 );
	} );

	// Merch
	$( '#merch .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-circle-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-circle-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		dots: false,
		margin: 40,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			768: {
				items: 2,
				margin: 30
			},
			1299: {
				items: 3
			}
		}
	} );

	// Instagram
	$.ajax( {
		url: 'https://www.juicer.io/api/feeds/jayden-tiko',
		method: 'GET',
		dataType: 'json',
		success: data => {
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.full_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 8 ) {
					break;
				}
			}

			$( '#ig-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );
} );
